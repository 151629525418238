<template>
  <div class="help-detail">
    <helloWorld />
    <div class="content-520">
      <p class="helpTop">
        <span></span>
        帮助中心
      </p>
      <div class="helpMain" v-if="id==1">
        <h3>
          如何创建一个新账户
        </h3>
        <div class="contents">
           您需要完成以下步骤，才可开始在平台上制造、交易数字藏品，让我们开始吧。创建平台账号，提交身份资料，进行身份认证，目前平台提供个人身份认证，个人身份认证包括：爱好者、艺术家等。完成身份认证后，个人可以去浏览数字藏品商城、创造数字藏品、购买数字藏品等。 
        </div>
      </div>
      <div class="helpMain" v-if="id==2">
        <h3>
          如何购买数字藏品？
        </h3>
        <div class="contents">
           购买数字藏品的前提是您需要完成爱好者认证；完成爱好者认证后，您可以在商城搜索或浏览数字藏品商品。目前平台提供一口价购买方式；在数字藏品商品详情页，您可以完成购买；点击购买按钮，完成交易后，实现商品链上的地址转移。 
        </div>
      </div>
      <div class="helpMain" v-if="id==3">
        <h3>
          如何创造数字藏品？
        </h3>
        <div class="contents">
           完成身份认证后，可以通过平台提供的“发布”入口开始自己的数字藏品创建之旅。您需要先创建作品专辑，点击“创造”按钮，在上传专辑封面图，编辑专辑介绍，点击“完成”编辑。在编辑完成页面可选择添加数字藏品商品，在上传商品文件，编辑商品属性及描述，等待平台审核。平台审核通过后，您可以在个人中心查看数字藏品商品列表，在藏品详情页面选择商品上架。点击上架后，您的商品可以在商城页面被搜索或浏览到。平台会优先从安全及用户体验角度考虑，不断更新功能与版本，教程在随后的版本中会补齐。 
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  mounted() {
    this.id=this.$route.query.id
  },
  data() {
    return {
      id: ''
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.content-520 {
  max-width: 888px;
  margin: auto;
  padding-top: 50px;
}
.helpTop{
  display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #1d202b;
    margin-bottom: 40px;
}
.helpTop span{
  width: 4px;
    height: 20px;
    background: #3d61ff;
    display: inline-block;
    margin-right: 10px;
}
.help-detail .helpMain {
    max-width: 840px;
    margin: 0 auto;
    padding: 0 16px;
}
.help-detail .helpMain h3 {
    max-width: 840px;
    font-size: 18px;
    font-weight: 400;
    color: #303030;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    position: relative;
    margin-bottom: 20px;
}
.help-detail .helpMain h3::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 345px;
    height: 2px;
    background: #2f54eb;
}
.help-detail .helpMain .contents {
    font-size: 14px;
    /* font-weight: 400; */
    color: #1d202b;
    line-height: 24px;
    /* min-height: 1150px; */
}
</style>